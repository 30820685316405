
// import $app from '@/plugins/modules'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IPatientPhoto } from '../types';

@Component
export default class CustomerPhotoPreview extends Vue {
  @Prop({ default: () => { } })
  readonly value: boolean | undefined;

  @Prop({ default: () => { } })
  readonly photo: IPatientPhoto | undefined;

  loaded = false;

  get visible(): boolean {
    return this.value!;
  }

  set visible(value: boolean) {
    this.$emit('input', value)
  }
}
